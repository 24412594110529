<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Регистрация" />
      <RegistrationForm :nmicToken="nmicToken" :regions="preparedRegions" />
    </div>
    <div v-else>Не удалось загрузить список регионов</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import RegistrationForm from '@/components/forms/RegistrationForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

import { API_REGIONS_LIST } from '@/store/const/apiPaths'

import { loadPageData } from '@/mixins/loadPageData'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю список регионов',
      errorMessage: 'Не удалось загрузить список регионов',
    }
  },
  components: {
    RegistrationForm,
    PageHeader,
    DisplayLoading,
  },
  mixins: [loadPageData],
  computed: {
    loadUrl() {
      return API_REGIONS_LIST
    },
    nmicToken() {
      return this.$route.params.token
    },
    regions() {
      return this.pageData
    },
    preparedRegions() {
      return this.regions
        .map(r => ({
          id: r.id,
          name: r.name_with_type,
        }))
        .sort((a, b) => a.id - b.id)
    },
  },
}
</script>
