<template>
  <v-form @submit.prevent="onSubmit" ref="form">
    <v-text-field
      v-model="formData.login"
      label="Придумайте логин"
      :rules="[
        rules.required,
        rules.noBeginSpaces,
        rules.noEndSpaces,
        rules.noSpaces,
      ]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.email"
      label="Email"
      :rules="[rules.required, rules.email]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.fio"
      label="ФИО"
      :rules="[rules.required]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.organization"
      label="Организация"
      :rules="[rules.required]"
      validate-on-blur
    ></v-text-field>
    <v-autocomplete
      v-if="regionNames.length"
      v-model="formData.region"
      :items="regionNames"
      label="Регион"
      :rules="[rules.required]"
      validate-on-blur
      no-data-text="Нет такого региона"
    ></v-autocomplete>
    <v-text-field
      v-model="formData.password1"
      :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword1 ? 'text' : 'password'"
      @click:append="showPassword1 = !showPassword1"
      label="Пароль"
      :rules="[rules.required, rules.password1]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.password2"
      :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword1 ? 'text' : 'password'"
      @click:append="showPassword1 = !showPassword1"
      label="Повторите пароль"
      :rules="[rules.required, rules.password2]"
      validate-on-blur
    ></v-text-field>
    <FormSaveButton text="Зарегистрироваться" />
  </v-form>
</template>

<script>
import FormSaveButton from '@/components/buttons/FormSaveButton.vue'

import { register } from '@/lib/requestsAPI'

import { routeParams } from '@/mixins/routeParams'
import { formMixin } from '@/mixins/formMixin'
import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'

import { EMAIL_PATTERN } from '@/store/const/emailPattern'
import { COMPONENT_PATHS, LOGIN } from '@/store/const/path'

export default {
  props: ['nmicToken', 'regions'],
  mixins: [formMixin, routeParams, sendFormWithToastAndRedirect],
  components: {
    FormSaveButton,
  },
  data() {
    return {
      showPassword1: false,
      showPassword2: false,
      formData: {},
      TEMPLATE_FORM_DATA: {
        password1: null,
        password2: null,
        fio: null,
        email: null,
        login: null,
        organization: null,
        region: null,
        token: null,
      },
      rules: {
        required: v => !!v || 'Обязательное поле',
        email: v => !v || EMAIL_PATTERN.test(v) || 'Неправильный адрес почты.',
        password1: v => !v || v.length > 7 || 'Пароль минимум 8 знаков',
        password2: v => v === this.formData.password1 || 'Пароли не совпадают',
        noBeginSpaces: v =>
          !/^\s/.test(v) ||
          'Логин не должен содержать пробелы. (Пробел в начале строки)',
        noEndSpaces: v =>
          !/\s$/.test(v) ||
          'Логин не должен содержать пробелы. (Пробел в конце строки)',
        noSpaces: v => !/\s/.test(v) || 'Логин не должен содержать пробелы',
      },
    }
  },
  created() {
    this.TEMPLATE_FORM_DATA.token = this.nmicToken
  },
  computed: {
    regionNames() {
      return this.regions.map(r => r.name)
    },
  },
  methods: {
    prepareSendData() {
      const password = this.formData.password1
      const region_id = this.regions.find(
        r => r.name === this.formData.region
      ).id
      return {
        ...this.formData,
        password,
        region_id,
      }
    },
    add() {
      this.sendFormWithToastAndRedirect({
        action: register,
        data: { nmicToken: this.nmicToken, data: this.prepareSendData() },
        successPath: COMPONENT_PATHS[LOGIN].path,
        successMessage: 'Вы зарегистрированы',
        errorMessage: 'Регистрация не удалась',
      })
    },
  },
}
</script>
